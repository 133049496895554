<template>
    <div></div>
</template>

<script>
export default {
  name: "Auth",
  async created() {
    // 如果连接中有微信返回的 code，则用此 code 调用后端接口，向微信服务器请求用户信息
    // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
    // console.log(this.$route.query);
    // console.log(this.$api)
    
    if (this.$route.query.code) {
      try {
        let redirectUrl = sessionStorage.getItem("wxRedirectUrl");
        let res = await this.$api.getWxUserInfo({
          wxCode: this.$route.query.code
        });

        // console.log(res, "1111");
        if (res.data.code == 200) {
          const token = res.headers['accesstoken']
          localStorage.setItem('token', token)
          localStorage.setItem("wxUserInfo", JSON.stringify(res.data.root));
          this.$store.dispatch('setUserInfo', res.data.root)
          localStorage.setItem("openId", res.data.root.openId);
        }
        this.$router.replace(redirectUrl);
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      this.$router.replace("/");
    }
  },
  methods: {
    getCode(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>